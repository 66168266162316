import React, { useEffect, useState, useMemo } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Nav, OverlayTrigger, ProgressBar, InputGroup, FormControl, Row, Table, Tooltip, ListGroup, Dropdown } from "react-bootstrap";
import { Doughnut } from 'react-chartjs-2';
import { FaBook, FaQuestionCircle, FaLightbulb, FaGem } from 'react-icons/fa';
import '../../assets/css/UserDashboard.css'
import { calculateCollegeListBalance } from '../../utils/collegeListUtils';
import { startAppTour } from '../../tours/firstAppTour'; // Adjust the path as needed


export default function MainDashboard() {
  const [currentNotification, setCurrentNotification] = useState(0);
  const [friends, setFriends] = useState([]);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [currentResourceIndex, setCurrentResourceIndex] = useState(0);
  const [userInteractedActivities, setUserInteractedActivities] = useState(false);
  const [userInteractedResources, setUserInteractedResources] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [collegeDetails, setCollegeDetails] = useState([]);
  const [summary, setSummary] = useState({
    reach: 0,
    target: 0,
    safety: 0,
    averagePrice: 0,
    totalColleges: 0,
  });
  const [userSAT, setUserSAT] = useState(1200); // Placeholder SAT score
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [applications, setApplications] = useState([]);
  const user = { user_type: 2, name: "John Doe" }; // Placeholder user data
  const userName = user.name;
  const [averageTimeToComplete, setAverageTimeToComplete] = useState(14); // Placeholder average time to complete
  const [notifications, setNotifications] = useState([]);
  const [randomTip, setRandomTip] = useState(null);
  const [showAllColleges, setShowAllColleges] = useState(false);
  const listBalance = useMemo(() => {
    const safety = collegeDetails.filter(college => college.category === 'Safety').length;
    const target = collegeDetails.filter(college => college.category === 'Target').length;
    const reach = collegeDetails.filter(college => college.category === 'Reach').length;
    return calculateCollegeListBalance(safety, target, reach, collegeDetails.length);
  }, [collegeDetails]);
  const [randomArticle, setRandomArticle] = useState(null);
  const [predominantMajors, setPredominantMajors] = useState("Computer Science"); // Placeholder major
  const [randomFaq, setRandomFaq] = useState(null);

  useEffect(() => {
    startAppTour(navigate);
  }, [navigate]);


  useEffect(() => {
    // Placeholder data for SAT
    setUserSAT(1200);
  }, []);

  useEffect(() => {
    // Placeholder data for Random FAQ
    setRandomFaq({
      title: "What is the SAT?",
      description: "The SAT is a standardized test used for college admissions in the United States.",
      body: "The SAT measures a student's readiness for college and provides colleges with one common data point that can be used to compare all applicants.",
    });
  }, []);

  useEffect(() => {
    // Placeholder data for applications, friends, and colleges
    setApplications([
      { status: 'not started', application_fee: '50', major: 'Computer Science' },
      { status: 'in progress', application_fee: '70', major: 'Engineering' },
      { status: 'submitted', application_fee: '90', major: 'Computer Science' },
    ]);
    setFriends([
      { name: "Alice", avatarUrl: "/path/to/avatar1.jpg" },
      { name: "Bob", avatarUrl: "/path/to/avatar2.jpg" },
    ]);
    setColleges([
      { college_id: 1, INSTNM: "Harvard University" },
      { college_id: 2, INSTNM: "Stanford University" },
    ]);
    setCollegeDetails([
      { name: "Harvard University", sat_avg: 1400, category: "Reach" },
      { name: "Stanford University", sat_avg: 1350, category: "Target" },
      { name: "Local Community College", sat_avg: 1000, category: "Safety" },
    ]);
    setSummary({
      reach: 33,
      target: 33,
      safety: 33,
      averagePrice: 25000,
      totalColleges: 3,
    });
  }, [userSAT]);

  useEffect(() => {
    // Placeholder data for Random Article
    setRandomArticle({
      title: "Top 10 Tips for College Applications",
      description: "Discover the best tips to enhance your college application.",
      body: "Applying to college can be a daunting task, but with these top 10 tips, you'll be well on your way to success.",
      related: ["How to Write a Great College Essay", "Common Application Mistakes"],
      link: "#",
    });
  }, []);

  useEffect(() => {
    // Placeholder data for Random Tip
    setRandomTip({
      title: "Stay Organized",
      description: "Keep track of deadlines and requirements to ensure a smooth application process.",
      body: "Use a calendar or app to manage your application deadlines and ensure you're submitting all necessary materials on time.",
      category: "Application Tips",
    });
  }, []);

  useEffect(() => {
    // Placeholder data for Notifications
    setNotifications([
      {
        data: {
          subject: "Application Deadline Reminder",
          message: "Don't forget to submit your application to Stanford by December 1st.",
          created_at: "2024-08-25",
        },
      },
      {
        data: {
          subject: "New College Match Available",
          message: "A new college match has been found based on your preferences.",
          created_at: "2024-08-27",
        },
      },
    ]);
  }, []);

  const applicationStatusData = useMemo(() => {
    const statusCounts = applications.reduce((acc, app) => {
      acc[app.status] = (acc[app.status] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: ['Not Started', 'In Progress', 'Submitted', 'Accepted', 'Rejected'],
      datasets: [
        {
          data: [
            statusCounts['not started'] || 0,
            statusCounts['in progress'] || 0,
            statusCounts['submitted'] || 0,
            statusCounts['accepted'] || 0,
            statusCounts['rejected'] || 0,
          ],
          backgroundColor: ['#6c5ce7', '#0984e3', '#007bff', '#28a745', '#dc3545'],
          hoverBackgroundColor: ['#a29bfe', '#74b9ff', '#4287f5', '#55efc4', '#fab1a0'],
        },
      ],
    };
  }, [applications]);

  const numberOfApplications = applications.length;
  const moneySpent = applications.reduce((total, app) => {
    return total + parseFloat(app.application_fee || 0);
  }, 0);

  const [skin, setSkin] = useState(localStorage.getItem('skin-mode') || '');

  const regStyle = {
    selected: {
      fill: "#506fd9"
    },
    initial: {
      fill: skin === 'dark' ? "#192030" : "#d9dde7"
    }
  };

  const fetchSuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    // Placeholder data for suggestions
    setSuggestions([
      { INSTNM: "Harvard University" },
      { INSTNM: "Stanford University" },
      { INSTNM: "MIT" },
    ]);
    setShowSuggestions(true);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    fetchSuggestions(query);
  };

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  };

  const handleSearchFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleSearch = () => {
    // Placeholder data for search results
    setSearchResults([
      { INSTNM: "Harvard University", CITY: "Cambridge", STABBR: "MA" },
      { INSTNM: "Stanford University", CITY: "Stanford", STABBR: "CA" },
    ]);
    setShowSuggestions(false);
  };

  const handleAddCollege = (college) => {
    setColleges([...colleges, college]);
    setShowSuggestions(false);
  };

  const activities = useMemo(() => [
    {
      type: 'Recent Activity',
      items: [
        { icon: 'ri-heart-3-line', text: 'David liked your photo.' },
        { icon: 'ri-heart-3-line', text: 'Alice liked your post.' },
      ]
    },
    {
      type: 'Friend List',
      items: friends.slice(0, 2).map((friend) => ({
        name: friend.name,
        avatarUrl: friend.avatarUrl
      }))
    }
  ], [friends]);

  const currentActivity = activities[currentActivityIndex];

  const nextActivity = () => {
    setUserInteractedActivities(true);
    setCurrentActivityIndex((prevIndex) => (prevIndex + 1) % activities.length);
  };

  const previousActivity = () => {
    setUserInteractedActivities(true);
    setCurrentActivityIndex((prevIndex) => (prevIndex - 1 + activities.length) % activities.length);
  };

  const resources = useMemo(() => [
    {
      type: 'Guide',
      icon: <FaBook className="me-2 text-primary" />,
      title: randomArticle ? randomArticle.title : 'Loading Guide...',
      description: randomArticle ? randomArticle.description : 'Fetching a random guide...',
      more: randomArticle ? randomArticle.body : 'Please wait while we load your guide.',
      related: randomArticle ? randomArticle.related : [],
      link: randomArticle ? randomArticle.link : '#'
    },
    {
      type: 'FAQ',
      icon: <FaQuestionCircle className="me-2 text-warning" />,
      title: randomFaq ? randomFaq.title : 'Loading FAQ...',
      description: randomFaq ? randomFaq.description : 'Fetching a random FAQ...',
      more: randomFaq ? randomFaq.body : 'Please wait while we load your FAQ.',
      related: [],
      link: '/faq'
    },
    {
      type: 'Tip',
      icon: <FaLightbulb className="me-2 text-success" />,
      title: randomTip ? randomTip.title : 'Loading Tip...',
      description: randomTip ? randomTip.description : 'Fetching a random tip...',
      more: randomTip ? randomTip.body : 'Please wait while we load your tip.',
      related: [],
      category: randomTip ? randomTip.category : null,
      link: '#'
    }
  ], [randomTip, randomFaq, randomArticle]);

  useEffect(() => {
    if (userInteractedResources) return;

    const interval = setInterval(() => {
      setCurrentResourceIndex((prevIndex) => (prevIndex + 1) % resources.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [resources.length, userInteractedResources]);

  const currentResource = resources[currentResourceIndex];
  const nextResource = () => {
    setUserInteractedResources(true);
    setCurrentResourceIndex((prevIndex) => (prevIndex + 1) % resources.length);
  };

  const previousResource = () => {
    setUserInteractedResources(true);
    setCurrentResourceIndex((prevIndex) => (prevIndex - 1 + resources.length) % resources.length);
  };

  const nextNotification = () => {
    setCurrentNotification((prev) => (prev + 1) % notifications.length);
  };

  const previousNotification = () => {
    setCurrentNotification((prev) => (prev - 1 + notifications.length) % notifications.length);
  };

  const CollegeMatchingSummary = () => {
    const colleges = [
      { name: "Harvard", match: "61.65%" },
      { name: "MIT", match: "61.64%" },
      { name: "Boston College", match: "61.63%" },
      { name: "Miami College", match: "61.62%" },
      { name: "Stanford", match: "61.60%" },
      { name: "NYU", match: "61.55%" },
    ];

    return (
      <Card className="card-one" style={{ position: 'relative', borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title as="h6" className="mb-0">Quick College Matches</Card.Title>
        </Card.Header>
        <Card.Body>
          <ProgressBar now={87.76} label={`${87.76}%`} />
          <p className="small text-muted mt-2">Based on your academic performance and extracurricular activities</p>
          <ListGroup variant="flush">
            {colleges.slice(0, 3).map((college, index) => (
              <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center" style={{ padding: '10px 15px' }}>
                <span>{college.name}</span>
                <span className="badge bg-primary">{college.match} Match</span>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div className="mt-3 text-center">
            <Link to="/discovery" className="text-primary">Discover More</Link>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div  className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 id="step1-tour" className="main-title mb-0">Welcome {userName}!</h4>
          </div>
        </div>

        <Row id="main-app-tour" className="g-3 justify-content-center">
          <Col md="6" xl="4">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Header className="d-flex justify-content-between align-items-center" style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #dee2e6', borderRadius: '10px 10px 0 0' }}>
                <Card.Title as="h6" className="mb-0">{currentActivity.type === 'Friend List' ? 'Friend List' : 'Recent Activities'}</Card.Title>
                <div className="d-flex">
                  <Button variant="light" size="sm" onClick={previousActivity}>&lt;</Button>
                  <Button variant="light" size="sm" onClick={nextActivity}>&gt;</Button>
                </div>
              </Card.Header>
              <Card.Body className="p-3">
                <ListGroup variant="flush" className="small">
                  {currentActivity.items.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex align-items-center py-2"
                      style={{ borderBottom: '1px solid #dee2e6', cursor: 'pointer', transition: 'all 0.3s ease-in-out', backgroundColor: '#ffffff' }}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f8f9fa'}
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ffffff'}
                    >
                      {item.avatarUrl && (
                        <img
                          src={item.avatarUrl}
                          alt="Avatar"
                          className="rounded-circle me-3"
                          style={{ width: '30px', height: '30px' }}
                        />
                      )}
                      <span className="fw-medium">{item.name}</span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                {friends.length > 2 && currentActivity.type === 'Friend List' && (
                  <Button variant="primary" size="sm" className="mt-2 w-100" style={{ borderRadius: '5px' }} onClick={() => navigate('/friends')}>
                    View All
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Body>
                <h4 className="card-value mb-3">Notifications</h4>
                {notifications.length > 0 ? (
                  <div className="d-flex align-items-start mb-2">
                    <i className="ri-notification-3-line fs-5 text-primary me-3"></i>
                    <div>
                      <p className="mb-1 fw-bold">{notifications[currentNotification].data.subject}</p>
                      <p className="mb-0 text-muted">{notifications[currentNotification].data.message}</p>
                    </div>
                  </div>
                ) : (
                  <p className="text-muted">No notifications found</p>
                )}
                {notifications.length > 1 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <button 
                      className="btn btn-sm btn-light" 
                      onClick={previousNotification} 
                      aria-label="Previous Notification">
                      <i className="ri-arrow-left-s-line"></i>
                    </button>
                    <span className="text-muted">+{notifications.length - 1} more</span>
                    <button 
                      className="btn btn-sm btn-light" 
                      onClick={nextNotification} 
                      aria-label="Next Notification">
                      <i className="ri-arrow-right-s-line"></i>
                    </button>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col md="6" xl="4" className="d-flex align-items-start">
            <Card 
              className="card-one" 
              style={{ 
                position: 'relative', 
                overflow: 'visible', 
                borderRadius: '10px', 
                backgroundColor: '#ffffff', 
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' 
              }}
            >
              <Card.Body>
                <h4 className="card-value mb-3">Quick College Search</h4>
                <p className="card-title text-dark mb-3">Find your desired college quickly</p>
                <div style={{ position: 'relative' }}>
                  <InputGroup>
                    <FormControl
                      placeholder="Search for a college"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onBlur={handleSearchBlur}
                      onFocus={handleSearchFocus}
                    />
                    <Button variant="primary" onClick={handleSearch}>Search</Button>
                  </InputGroup>
                  {showSuggestions && suggestions.length > 0 && (
                    <Dropdown.Menu 
                      show 
                      style={{
                        display: 'block',
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: 'calc(100% + 30px)',
                        maxHeight: '300px',
                        overflowY: 'auto',
                        zIndex: 1050,
                        backgroundColor: '#fff',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '0 0 4px 4px',
                      }}
                    >
                      {suggestions.map((suggestion, index) => (
                        <Dropdown.Item key={index} onClick={() => handleAddCollege(suggestion)}>
                          {suggestion.INSTNM}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>

          {searchResults.length > 0 && (
            <Col md="12" style={{overflow: 'visible'}}>
              <Card className="card-one" style={{overflow: 'visible'}}>
                <Card.Header>
                  <Card.Title as="h6">Search Results</Card.Title>
                </Card.Header>
                <Card.Body>
                  {searchResults.map((result, index) => (
                    <div key={index} className="college-item mb-3">
                      <Row>
                        <Col md="9">
                          <h5>{result.INSTNM}</h5>
                          <p>{result.CITY}, {result.STABBR}</p>
                          <Button onClick={() => handleAddCollege(result)}>Add to List</Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          )}

          <Col md="7" xl="8">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  {currentResource.icon}
                  <Card.Title as="h6" className="mb-0">{currentResource.type}</Card.Title>
                </div>
                <div className="d-flex">
                  <Button variant="light" size="sm" onClick={previousResource}>&lt;</Button>
                  <Button variant="light" size="sm" onClick={nextResource}>&gt;</Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Link to={currentResource.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <h5 className="fw-bold">{currentResource.title}</h5>
                  <p className="mb-2">{currentResource.description}</p>
                  <p className="mb-4 small" style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {currentResource.more}
                  </p>
                </Link>

                <Row className="mb-4">
                  <Col md="6">
                    <h6>Related Resources:</h6>
                    <ul className="list-unstyled mb-0">
                      {(currentResource.related || []).map((item, index) => (
                        <li key={index} className="text-primary">
                          <i className="ri-arrow-right-s-line me-1"></i> {item}
                        </li>
                      ))}
                    </ul>
                  </Col>
                  {currentResource.type === 'Tip' && currentResource.category && (
                    <Col md="6" className="d-flex flex-column align-items-start">
                      <div>
                        <h6>Category:</h6>
                        <p>{currentResource.category}</p>
                      </div>
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md="5" xl="4">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Header>
                <Card.Title as="h6">College List Summary</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                {summary.totalColleges === 0 ? (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <p className="fs-md fw-bold mb-3">No colleges in the list</p>
                    <p className="fs-sm text-muted mb-1">Start adding colleges to see your summary here.</p>
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div>
                        <h2 className="performance-value mb-0 d-flex flex-column">
                          <span style={{ fontSize: '1.5rem', lineHeight: '1.2' }}>{listBalance}</span>
                          <small 
                            className={`d-flex align-items-center ${listBalance === 'Balanced' ? 'text-success' : 'text-warning'}`}
                            style={{ fontSize: '0.75rem', marginTop: '0.25rem' }}
                          >
                            <i className={`ri-arrow-${listBalance === 'Balanced' ? 'up' : 'down'}-line me-1`}></i>
                            {listBalance === 'Balanced' ? 'Well distributed' : 'Needs adjustment'}
                          </small>
                        </h2>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <div className="d-flex align-items-center">
                          <p className="fs-sm text-muted mb-0 me-2">Avg. Net Price:</p>
                          <p className="fs-sm fw-medium mb-0">${summary.averagePrice}</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="fs-sm text-muted mb-0 me-2">Items:</p>
                          <p className="fs-sm fw-medium mb-0">{summary.totalColleges}</p>
                        </div>
                      </div>
                    </div>

                    <label className="card-title fs-sm fw-medium">Acceptance Distribution</label>

                    <ProgressBar className="progress-one ht-8 mt-2 mb-4">
                      <ProgressBar now={summary.safety} variant="success" />
                      <ProgressBar now={summary.target} variant="warning" />
                      <ProgressBar now={summary.reach} variant="danger" />
                    </ProgressBar>

                    <Table className="table-three">
                      <tbody>
                        {collegeDetails.slice(0, showAllColleges ? collegeDetails.length : 3).map((college, index) => (
                          <tr key={index} style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
                            <td><div className={`badge-dot bg-${college.category === 'Safety' ? 'success' : college.category === 'Reach' ? 'danger' : 'warning'}`}></div></td>
                            <td>{college.name}</td>
                            <td>{college.category}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    
                    {collegeDetails.length > 3 && (
                      <Button 
                        variant="link" 
                        onClick={() => setShowAllColleges(!showAllColleges)}
                        className="mt-2"
                      >
                        {showAllColleges ? "Show Less" : "Show More"}
                      </Button>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col md="6">
            <Card className="card-one" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
              <Card.Header>
                <Card.Title as="h6">Application Summary</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {applications.length === 0 ? (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <p className="fs-md fw-bold mb-3">No applications available</p>
                    <p className="fs-sm text-muted mb-1">Start applying to colleges to see your application summary here.</p>
                  </div>
                ) : (
                  <Row>
                    <Col md="4" className="d-flex align-items-center justify-content-center">
                      <div style={{ position: 'relative', width: '180px', height: '180px' }}>
                        <Doughnut 
                          data={applicationStatusData} 
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false
                              }
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3" className="d-flex align-items-center justify-content-center">
                      <div style={{ paddingLeft: '10px' }}>
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                          {applicationStatusData.labels.map((label, index) => (
                            <li key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                              <span style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                backgroundColor: applicationStatusData.datasets[0].backgroundColor[index],
                                marginRight: '8px'
                              }}></span>
                              <span>{label}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                    <Col md="5" className="d-flex flex-column justify-content-center">
                      <p className="fs-sm text-muted mb-1">Number of Applications</p>
                      <p className="fs-md fw-bold mb-3">{numberOfApplications}</p>

                      <p className="fs-sm text-muted mb-1">Average Time to Complete</p>
                      <p className="fs-md fw-bold mb-3">{averageTimeToComplete ? `${averageTimeToComplete.toFixed(2)} days` : 'N/A'}</p>

                      <p className="fs-sm text-muted mb-1">Predominant Major</p>
                      <p className="fs-md fw-bold mb-3">{predominantMajors || 'N/A'}</p>

                      <p className="fs-sm text-muted mb-1">Money Spent</p>
                      <p className="fs-md fw-bold mb-0">${moneySpent.toFixed(2)}</p>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col md="6">
            <CollegeMatchingSummary />
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}
